import React from 'react'
import AddForm from '../components/forms/AddForm'
import AddInfo from '../components/AddInfo'
import '../styles/add.css'
const Add = () => {

  return (
    <div className='addContainer'>
      <AddForm/>
      <AddInfo/>
    </div>
  )
}

export default Add
