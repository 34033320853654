import React from 'react'
import SoldList from '../components/lists/SoldList'
function Sold() {
  return (
    <div>
      <h1 className='invHeader'>Sold Boxes</h1>
      <SoldList/>
    </div>
  )
}

export default Sold
